<template>
  <Layout>
    <BHeader>
      <el-form
        ref="form"
        :inline="true"
        :model="formData"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item>
              <el-input
                v-model.trim="formData.errCode"
                placeholder="错误代码"
              />
            </el-form-item>
            <el-form-item>
              <el-select
                v-model="formData.errType"
                placeholder="报错类型"
                clearable
              >
                <el-option
                  v-for="(item, index) in errorTypeList"
                  :key="index"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-input
                v-model="formData.userId"
                clearable
                placeholder="用户ID"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-form-item label="报错时间">
              <el-date-picker
                v-model="datetimerange"
                type="datetimerange"
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template v-slot:right>
        <el-button
          type="primary"
          @click="handleSearch"
        >
          搜索
        </el-button>
      </template>
    </BHeader>

    <el-table
      v-loading="logListInfo.loading"
      :data="logListInfo.list"
      stripe
    >
      <el-table-column
        prop="userId"
        label="用户ID"
        min-width="100"
      />
      <el-table-column
        prop="errType"
        label="报错类型"
        min-width="100"
      >
        <template v-slot="{row}">
          {{ (errorTypeMap[row.errType] || {}).name || '未知' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="errTime"
        label="报错时间"
        min-width="100"
      />
      <el-table-column
        prop="errCode"
        label="错误代码"
        min-width="100"
      />
    </el-table>
    <Pagination
      :total="logListInfo.total"
      :page.sync="formData.pageNum"
      :limit.sync="formData.pageSize"
      @pagination="queryLogList"
    />
  </Layout>
</template>

<script>
import { errorCodeList, errorTypeList, errorTypeMap } from '@/utils/selectOptions'
import { getErrorInfo } from '@/api/log'

import { getYesterdaySection } from '@/utils'

export default {
  name: 'ErrorLog',
  data () {
    return {
      errorCodeList,
      errorTypeMap,
      errorTypeList,
      formData: {
        pageNum: 1,
        pageSize: 20
      },
      datetimerange: getYesterdaySection('timestamp'),
      logListInfo: {
        loading: false,
        total: 0,
        list: []
      }
    }
  },
  created () {
    this.queryLogList()
  },
  methods: {
    handleSearch () {
      this.formData.pageNum = 1
      this.queryLogList()
    },
    queryLogList () {
      this.logListInfo.loading = true
      let startTime
      let endTime
      if (this.datetimerange && this.datetimerange.length) {
        startTime = this.datetimerange[0] / 1000 + 8 * 60 * 60
        endTime = this.datetimerange[1] / 1000 + 8 * 60 * 60
      }
      getErrorInfo({
        ...this.formData,
        startTime,
        endTime
      })
        .then(res => {
          this.logListInfo.list = res.data.list
          this.logListInfo.total = res.data.count
        })
        .finally(() => {
          this.logListInfo.loading = false
        })
    }
  }
}
</script>

<style scoped lang="less">

</style>
